<template>
  <div class="biography">
    <h1>Biography</h1>
    <div class="biography-wrapper">
      <img :src="biographyData.imageFile">
      <div class="biography-text" v-html="biographyData.pageText"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API from '../services/api'

  export default {
  data() {
    return {
      biographyData: '',
    }
  },
  mounted() {
   this.init()   
  },
  methods:{
    init(){
      this.getContent();
    },
    async getContent(){
      API.getBiography().then(data => {
        //Method only returns one exhibit so we can use the index
        this.biographyData = data[0];

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    },
  }
}
</script>


<style lang="scss" scoped>
.biography {
  margin-top: 140px;
  height: 100vh;
  margin-left: 75px;
  margin-right: 75px;
  padding-top: 10px;
}
.biography-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 250px;
  margin-top: 25px;
}
.biography-text{
  width: 50%;
  // column-count: 2;
  // column-gap: 40px;
  text-align: left;
  font-weight: 500;
  line-height: 1.5;
  font-size: 18px;
  :first-child{
    margin-top: 0px;
  }
}
.biography-text p {
  margin-top: 0;
  line-height: 1.5;
}
.biography-text {
  :nth-child(n+8) {
  width: 200%;
  margin-left: -100%;
  }
}
@media (max-width: 1550px) {
  .biography-text {
    :nth-child(n+7) {
      width: 200%;
      margin-left: -100%;
    }
  }
}
@media (max-width: 1400px) {
  .biography-text {
    :nth-child(n+7) {
      width: 200%;
      margin-left: -100%;
    }
  }
}
@media (max-width: 1300px) {
  .biography-text {
    :nth-child(n+6) {
      width: 200%;
      margin-left: -100%;
    }
  }
}

@media (max-width: 1150px) {
  .biography-text {
    :nth-child(n+5) {
      width: 200%;
      margin-left: -100%;
    }
  }
}

@media (max-width: 1000px) {
  .biography-text {
    :nth-child(n+4) {
      width: 200%;
      margin-left: -100%;
    }
  }
}
@media (max-width: 850px) {
  .biography-text {
    p {
      width: 100% !important;
      margin-left: 0 !important;
    }
    :nth-child(n) {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}
.biography-wrapper img{
  max-height: 750px;
  width: 45%;
  height: 45%;
}
h1 {
  color: #801818;
  font-size: 30px;
  font-weight: 700;
}
h1, p{
  text-align: left;
}
@media (max-width: 1200px) {
  .biography-text{
    column-count: 1;
  }
}
@media (max-width: 1000px) {
  .biography-text{
    column-count: initial;
  }
}
@media (max-width: 850px) {
  .biography {
    margin-top: 100px;
    padding-top: 50px;
  }
  .biography-wrapper img{
    width: 100%;
    height: 100%;
  }
  .biography-text {
    width: 100%;
    :first-child{
      margin-top: 25px;
    }
  }
}

@media (max-width: 550px) {
  .biography {
    margin-left: 5px;
    margin-right: 5px;
  }
  .biography-text{
    font-size: 16px;
  }
}


</style>